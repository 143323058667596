import { render, staticRenderFns } from "./detalleInsumo.vue?vue&type=template&id=5b622ddc&scoped=true"
import script from "./detalleInsumo.vue?vue&type=script&lang=js"
export * from "./detalleInsumo.vue?vue&type=script&lang=js"
import style0 from "./detalleInsumo.vue?vue&type=style&index=0&id=5b622ddc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b622ddc",
  null
  
)

export default component.exports